import React, { useContext } from "react";

import {
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { OrganizationsColumnDefinition } from "../../utils/organizations.columns";

import { SessionContext } from "../../../../context";

import { useRouteMatch } from "react-router-dom";
import {
  OrganizationsRoute,
  RootPath,
} from "../../../../components/navbar/routes";

import "./organizations.table.scss";

export function OrganizationsTable({
  data,
  responsiveStyles,
  actionMenuOptions,
  className,
}) {
  const { path } = useRouteMatch(RootPath + OrganizationsRoute.path);
  const { features } = useContext(SessionContext);
  const { canEditOrg } = features;
  const { edit } = actionMenuOptions;
  const columns = [
    OrganizationsColumnDefinition.Name(path, "All Organizations"),
    OrganizationsColumnDefinition.OrgCode,
    OrganizationsColumnDefinition.ExpirationDate,
    OrganizationsColumnDefinition.MaxTrainees,
    OrganizationsColumnDefinition.ChildOrgs,
    OrganizationsColumnDefinition.Products,
  ];

  const actions = [canEditOrg && edit];

  return (
    data && (
      <DataTable
        columns={columns}
        data={data}
        className={className}
        keyFields={["code"]}
        keyPrefix={"cs-organizations-table"}
        defaultSortPropName="name"
        defaultSortDirection={SortDirection.ASCENDING}
        responsiveStyles={responsiveStyles}
        actionMenuOptions={actions}
        rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
        virtualRowEnabled
      />
    )
  );
}

import React, { useEffect, useState, useContext } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";
import {
  formatDateDashes,
  getUTCDate,
  Product,
} from "@transfr-inc/dashboard-components/utils";

import { FormProvider, useForm } from "react-hook-form";
import {
  OrgDetailsEditor,
  OrgExpirationDateEditor,
  OrgFormEditor,
  OrgProductsEditor,
  OrgSelectParentEditor,
  OrgTraineeLimitEditor,
  OrgSimsEditor,
  OrgFormToggle,
  OrgTrekLicensesEditor,
  OrgSelectMenuEditor,
  OrgTrekCareersEditor,
} from "../../forms/org-editor";
import { getSimulationsOptions } from "../../forms/org-editor/org-sims.editor";
import { getCareerOptions } from "../../forms/org-editor/org-trek-careers.editor";
import { getProductsOptions } from "../../forms/org-editor/org-products.editor";
import { MULTI_ORG_PARENT } from "../../../constants/organization-types";
import { services } from "../../../dependencies";
import { useApiRequest } from "../../../utils/http-client";
import { OrganizationContext } from "../../../context";

import "./edit-org.modal.scss";

export default ({
  open,
  onClose,
  organization,
  onUpdateOrg,
  onParentChange,
}) => {
  const { updateOrganizations } = useContext(OrganizationContext);
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showTSMenu, setShowTSMenu] = useState();
  const [showSims, setShowSims] = useState();
  const [showTrek, setShowTrek] = useState();
  const [showCareers, setShowCareers] = useState();
  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });
  const { reset, handleSubmit, formState, watch } = methods;
  const {
    organizationService,
    menuBuilderService,
    careersService,
    trekService,
  } = services;
  const productsWatch = watch("products");

  const { response: parentOrgs = [], sendRequest: getParentOrgs } =
    useApiRequest(() => organizationService.getParentOrgs(), false);

  const { response: orgMenu, sendRequest: getOrgMenu } = useApiRequest(
    () => organizationService.getOrganizationMenu(organization?.code),
    false
  );

  const { response: tsMenus, sendRequest: getTSMenus } = useApiRequest(
    () => menuBuilderService.getAllMenus(),
    false
  );

  const { response: orgTrekCareers, sendRequest: getOrgTrekCareers } =
    useApiRequest(
      () => trekService.getOrganizationTreks(organization?.code),
      false
    );

  const { response: trekCareers, sendRequest: getTrekCareers } = useApiRequest(
    () => trekService.getOrganizationTreks(),
    false
  );

  const { response: orgSimulations, sendRequest: getOrgSimulations } =
    useApiRequest(
      () => organizationService.getOrganizationSimulations(organization?.code),
      false
    );

  const { response: sims = [], sendRequest: getSimulations } = useApiRequest(
    () => careersService.getAllSimulations(),
    false
  );

  const onConfirmChanges = () => {
    setLoading(true);
    setErrorMessage();

    handleSubmit(async (values) => {
      const dataUpdated = getUpdateData(values);
      try {
        const updatedOrg = await organizationService.updateOrgMetadata(
          dataUpdated
        );

        if (values.products.map((p) => p.id).includes(Product.TRK)) {
          await trekService.upsertOrganizationCareers(organization.code, {
            hasAllCareers: values.allCareers,
            careerIds: values.careers ? values.careers.map((p) => p.id) : [],
          });
        }

        updateOrganizations();
        onUpdateOrg && onUpdateOrg(updatedOrg);
        if (onParentChange) {
          onParentChange(values.parent);
        }
      } catch (error) {
        const message =
          error.data?.detail ?? "Something went wrong. Please try again.";
        setErrorMessage(message);
        console.error(
          "[Edit Org] - Something went wrong. Error Details >>>",
          error
        );
      } finally {
        setLoading();
      }
    })();
  };

  const getUpdateData = (data) => {
    const orgUpdated = { code: organization.code, name: data.name };

    if (organization.typeId !== MULTI_ORG_PARENT.id) {
      orgUpdated.traineeLimit =
        data.seats >= 0 && Number.parseInt(data.seats || 0);
      orgUpdated.trekLicenses =
        data.trekLicenses >= 0 && Number.parseInt(data.trekLicenses || 0);
      orgUpdated.instructorSeats = organization.instructorSeats;
      orgUpdated.expiration =
        data.expiration && formatDateDashes(data.expiration);
      orgUpdated.children = data?.children && data.children.map((c) => c.id);
      orgUpdated.parent = data.parent?.id;
      orgUpdated.products = data.products && data.products.map((p) => p.id);
      orgUpdated.studentExperience = data.studentExperience;
      orgUpdated.classroomMenuBuilder = data.classroomMenuBuilder;
      orgUpdated.menu = data.menu && data.menu.id;
      orgUpdated.allSims = data.allSims;
      orgUpdated.simulations = data?.simulations
        ? data.simulations.map((s) => s.id)
        : [];
      orgUpdated.integrations = data.integrations;
    }

    return orgUpdated;
  };

  const resetForm = () => {
    reset({
      ...organization,
      expiration: organization.expiration
        ? getUTCDate(organization.expiration)
        : undefined,
      products: getProductsOptions(organization.products),
      parent: organization.parent,
      menu: orgMenu,
      simulations: getSimulationsOptions(orgSimulations?.simulations),
      allSims: orgSimulations?.allSims,
      careers: getCareerOptions(orgTrekCareers),
      allCareers: trekCareers?.length == orgTrekCareers?.length,
      studentExperience: organization.studentExperience,
      classroomMenuBuilder: organization.classroomMenuBuilder,
      integrations: organization.integrations,
    });

    if (organization?.typeId !== MULTI_ORG_PARENT.id) {
      getParentOrgs();
      getTSMenus();
      getSimulations();
      getTrekCareers();
    }
  };

  useEffect(() => {
    if (organization?.typeId === MULTI_ORG_PARENT.id) {
      resetForm();
    } else if (organization?.code) {
      setLoading(true);
      getOrgMenu();
      getOrgSimulations();
      getOrgTrekCareers();
    }
  }, [organization]);

  useEffect(() => {
    if (organization?.code) {
      setLoading();
      resetForm();
    }
  }, [orgMenu, orgSimulations, orgTrekCareers]);

  useEffect(() => {
    setShowTSMenu(productsWatch?.find((p) => p.id === Product.TS));
    setShowSims(productsWatch?.find((p) => p.id === Product.CE));
    setShowTrek(productsWatch?.find((p) => p.id === Product.TRK));
    setShowCareers(productsWatch?.find((p) => p.id === Product.TRK));
  }, [productsWatch]);

  useEffect(() => {
    reset({});
  }, [open]);

  return (
    <Modal
      modalClassName={"edit-org-modal"}
      open={open}
      onClose={onClose}
      uniqueName="edit-org-modal"
    >
      {loading && <Loader overlay />}
      <ModalHeader
        className="blue-icon small"
        icon={["fa-regular", "edit"]}
        title="Edit Organization"
      ></ModalHeader>

      <ModalBody className="modal-org-details">
        <FormProvider {...methods}>
          <OrgFormEditor>
            {errorMessage && (
              <Notification
                animated
                closable
                onClose={() => setErrorMessage()}
                type={NotificationType.error}
              >
                {errorMessage}
              </Notification>
            )}
            <OrgDetailsEditor></OrgDetailsEditor>
            {organization?.typeId !== MULTI_ORG_PARENT.id && (
              <>
                <OrgProductsEditor
                  placeholder={"Select one or more products..."}
                ></OrgProductsEditor>
                {showTSMenu || showSims ? (
                  <OrgFormToggle
                    name="studentExperience"
                    title="Student Experience"
                    infoText="Would you like students to have the option to view progress through their own dashboard?"
                  ></OrgFormToggle>
                ) : (
                  <></>
                )}
                {showTSMenu && (
                  <div>
                    <OrgSelectMenuEditor menus={tsMenus} isEdit />
                    <OrgFormToggle
                      name="classroomMenuBuilder"
                      title="Classroom Menu Builder"
                      infoText="Would you like instructors to be able to create a custom menu for their classroom?"
                    ></OrgFormToggle>
                  </div>
                )}
                {showSims && (
                  <OrgSimsEditor required data={sims}></OrgSimsEditor>
                )}
                {showCareers && (
                  <OrgTrekCareersEditor
                    required
                    data={trekCareers}
                  ></OrgTrekCareersEditor>
                )}
                <OrgSelectParentEditor
                  orgs={parentOrgs}
                  clearEnabled
                ></OrgSelectParentEditor>
                <OrgFormToggle
                  className="edit-org-integrations"
                  name="integrations"
                  title="Integrations"
                  infoText="Would you like to enable external integrations?"
                ></OrgFormToggle>
                <OrgTraineeLimitEditor></OrgTraineeLimitEditor>
                {showTrek && <OrgTrekLicensesEditor name="trekLicenses" />}
                <OrgExpirationDateEditor></OrgExpirationDateEditor>
              </>
            )}
          </OrgFormEditor>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          primary
          onClick={onConfirmChanges}
          disabled={!formState.isValid}
        >
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};
